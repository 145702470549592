const openaiRouter = [
  {
    path: "/gpts",
    name: "GPTs",
    meta: {
      title: "GPTs",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/openai/gpts/index.vue"),
  },
  {
    path: "/gpts-settings",
    name: "GPTsSettings",
    meta: {
      title: "GPTsSettings",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/openai/gpts/settings.vue"),
  },
  //4
  {
    path: "/chat",
    name: "ChatGPT",
    meta: {
      title: "思拓AI",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/openai/chatgpt4/index.vue"),
  },
  {
    path: "/chat/settings",
    name: "HomeChatSettings",
    meta: {
      title: "思拓AI",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/openai/chatgpt4/settings.vue"),
  },
  //chatgpt4v
  {
    path: "/chat4v",
    name: "ChatGPT4V",
    meta: {
      title: "思拓AI",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/openai/chatgpt4v/index.vue"),
  },
  {
    path: "/chat4v/settings",
    name: "HomeChat4vSettings",
    meta: {
      title: "思拓AI",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/openai/chatgpt4v/settings.vue"),
  },
  //chatgpt3.5
  {
    path: "/chat3",
    name: "ChatGPT35",
    meta: {
      title: "思拓AI",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/openai/chatgpt3/index.vue"),
  },
  {
    path: "/chat35/settings",
    name: "HomeChat35Settings",
    meta: {
      title: "思拓AI",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/openai/chatgpt3/settings.vue"),
  },
];

export default openaiRouter;
