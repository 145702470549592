/*
 * @Date: 2024-02-19 14:19:24
 * @Author: 大年
 * @Version: 1.0
 * @Contact: 353036652@qq.com
 */

import { createRouter, createWebHistory } from "vue-router";
import { setDocumentTitle } from "@/utils/domUtil";
import store from "@/store";
import leonardoRouter from "./leonardo.router";
import openaiRouter from "./openai.router";
import replicateRouter from "./replicate.router";
import duckaigRouter from "./duckaig.router";
import midjourneyRouter from "./midjourney.router";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "思拓AI",
      needLogin: true,
      needTitle: true,
    },
    component: () => import("@/views/index/index.vue"),
  },
  {
    path: "/apps",
    name: "Apps",
    meta: {
      title: "AI应用",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/app/index.vue"),
  },
  {
    path: "/category-res/:guid",
    name: "AppsCategoryResult",
    meta: {
      title: "分类结果",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/app/categoryResult.vue"),
  },
  {
    path: "/news-app",
    name: "NewsAppResult",
    meta: {
      title: "最新AI应用列表",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/app/newsAppResult.vue"),
  },
  {
    path: "/gpts-app",
    name: "GPTsAppResult",
    meta: {
      title: "GPTs应用列表",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/app/gptsAppResult.vue"),
  },
  {
    path: "/app/:guid",
    name: "AppsDetail",
    meta: {
      title: "应用介绍",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/app/detail.vue"),
  },
  // 子应用路由配置, 其中 name 由后台配置好，应用列表直接按 app_component_name 跳转
  ...leonardoRouter,
  ...openaiRouter,
  ...replicateRouter,
  ...duckaigRouter,
  ...midjourneyRouter,
  {
    path: "/trends",
    name: "Trends",
    meta: {
      title: "思拓AI",
      needLogin: true,
      needTitle: true,
    },
    component: () => import("@/views/trends/index.vue"),
  },
  {
    path: "/trends/:guid",
    name: "TrendsDetail",
    props: true,
    meta: {
      title: "动态",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/trends/detail.vue"),
  },
  {
    path: "/mine",
    name: "Mine",
    meta: {
      title: "我的",
      needLogin: true,
    },
    component: () => import("@/views/mine/index"),
  },
  {
    path: "/profile",
    name: "Profile",
    meta: {
      title: "编辑资料",
      needLogin: true,
    },
    component: () => import("@/views/mine/settings/profile"),
  },
  {
    path: "/settings",
    name: "Settings",
    meta: {
      title: "设置",
      needLogin: true,
    },
    component: () => import("@/views/mine/settings/index"),
  },
  {
    path: "/make-point-share",
    name: "MakePointShare",
    meta: {
      title: "分享赚点数",
      needLogin: true,
    },
    component: () => import("@/views/mine/share/index"),
  },
  {
    path: "/bill",
    name: "Bill",
    meta: {
      title: "我的账单",
      needLogin: true,
    },
    component: () => import("@/views/mine/bill/index"),
  },
  {
    path: "/nickname",
    name: "Nickname",
    meta: {
      title: "用户昵称",
      needLogin: true,
    },
    component: () => import("@/views/mine/settings/nickname"),
  },
  {
    path: "/password",
    name: "Password",
    meta: {
      title: "登录密码",
      needLogin: true,
    },
    component: () => import("@/views/mine/settings/password"),
  },
  {
    path: "/paypassword",
    name: "PayPassword",
    meta: {
      title: "操作密码",
      needLogin: true,
    },
    component: () => import("@/views/mine/settings/paypassword"),
  },
  {
    path: "/realname",
    name: "Realname",
    meta: {
      title: "实名认证",
      needLogin: true,
    },
    component: () => import("@/views/mine/settings/realname"),
  },
  {
    path: "/realAlipay",
    name: "RealAlipay",
    meta: {
      title: "支付宝账号",
      needLogin: true,
    },
    component: () => import("@/views/mine/settings/realAlipay"),
  },
  {
    path: "/bindmobile",
    name: "BindMobile",
    meta: {
      title: "绑定手机",
      needLogin: true,
    },
    component: () => import("@/views/mine/settings/bindmobile"),
  },
  {
    path: "/menber",
    name: "Menber",
    meta: {
      title: "充值点数",
      needLogin: true,
    },
    component: () => import("@/views/mine/menber/index"),
  },
  {
    path: "/sign",
    name: "Sign",
    meta: {
      title: "签到",
      needLogin: true,
    },
    component: () => import("@/views/mine/sign/index"),
  },
  {
    path: "/agreement",
    name: "Agreement",
    meta: {
      title: "用户协议",
      needLogin: true,
    },
    component: () => import("@/views/common/agreement.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    meta: {
      title: "用户隐私",
      needLogin: true,
    },
    component: () => import("@/views/common/privacy.vue"),
  },
  {
    path: "/about",
    name: "About",
    meta: {
      title: "关于思拓",
      needLogin: true,
    },
    component: () => import("@/views/common/about.vue"),
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    meta: {
      title: "忘记密码",
    },
    component: () => import("@/views/login/forgotpassword.vue"),
  },
  {
    path: "/sso/login",
    name: "Login",
    meta: {
      title: "登录",
    },
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: () => import("@/views/pay/checkout.vue"),
  },
  {
    path: "/tips",
    name: "TipsWechat",
    component: () => import("@/views/utilsPage/tips.vue"),
  },
  {
    path: "/r/:code",
    name: "LandingPage",
    component: () => import("@/views/landing/index.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    meta: {},
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    return {
      left: 0,
      top: 0,
    };
  },
});

const isProduction = process.env.VUE_APP_DEPLOY_ENV == "prod";
router.beforeEach(async (to, from, next) => {
  if (isProduction && location.protocol !== "https:") {
    const { host } = location;
    location.href = `https://${host}`;
  } else {
    const title = to.meta?.title ? to.meta?.title : process.env.VUE_APP_TITLE;
    setDocumentTitle(!to.meta?.needTitle ? process.env.VUE_APP_TITLE : title);
    if (to.meta.needLogin && !store.getters?.currentUser) {
      return next({
        name: "Login",
        replace: true,
        query: {
          redirect_url: encodeURIComponent(to.fullPath),
        },
      });
    }
    next();
  }
});

export default router;
