const duckaigRouter = [
  {
    path: "/claude",
    name: "DuckaigClaude",
    meta: {
      title: "claude",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/duckaig/claude/index.vue"),
  },
  {
    path: "/claude-settings",
    name: "ClaudeSettings",
    meta: {
      title: "claude",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/duckaig/claude/settings.vue"),
  },
];

export default duckaigRouter;
