const leonardoRouter = [
  {
    path: "/leonardophotos",
    name: "LeonardoPhotos",
    meta: {
      title: "leonardo",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/leonardo/photos/index.vue"),
  },
  {
    path: "/leonardophotos-settings",
    name: "LeonardophotosSettings",
    meta: {
      title: "leonardo",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/leonardo/photos/settings.vue"),
  },
];

export default leonardoRouter;
