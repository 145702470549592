<template>
  <router-view />
</template>

<script>
import { defineComponent } from "vue";
import { pingToken } from "@/api/media.service";
import { useStore } from "vuex";
import { PURGE_AUTH } from "@/store/mutation.types";
export default defineComponent({
  setup() {
    const store = useStore();
    const pingTokenFn = async () => {
      try {
        const { result } = await pingToken();
        if (!result) {
          store.commit(PURGE_AUTH);
        }
      } catch (error) {
        console.log(error);
      }
    };
    pingTokenFn();
  },
});
</script>

<style>
#app {
  width: 750px;
  min-height: 100vh;
  background: #fff;
  margin: 0 auto;
}
</style>
