const midjourneyRouter = [
  {
    path: "/midjourneydraw",
    name: "MidjourneyDraw",
    meta: {
      title: "midjourney",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/midjourney/draw/index.vue"),
  },
  {
    path: "/midjourneydraw-settings",
    name: "MidjourneySettings",
    meta: {
      title: "Midjourney",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/midjourney/draw/settings.vue"),
  },
];

export default midjourneyRouter;
