import ApiService from "@/api/api.service";
import {
  LOGIN,
  LOGIN_WITH_PASSWORD,
  CHECK_SCAN_RESULT,
  LOGOUT,
  VERIFY_AUTH,
  SET_AUTH,
  PURGE_AUTH,
  FORCE_LOGIN,
  SET_USER_INFO,
  GET_USER_INFO_API,
} from "@/store/mutation.types";
import { clearToken, setToken, getCache } from "@/utils/util";
import { showToast, showLoadingToast, closeToast } from "vant";
import { login, logout } from "@/api/auth.service";
import { getUserInfo } from "@/api/wechat.service";
// 用户状态数据
const state = {
  currentUser: JSON.parse(getCache("currentUser")),
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(async (resolve, reject) => {
      try {
        showLoadingToast("正在登录...");
        const { result } = await login(credentials);

        if (result) {
          context.commit(SET_AUTH, result);
          showToast("登录成功");
          resolve();
        } else {
          showToast("用户信息为空");
        }
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        console.log(msg);
      }
    });
  },
  [LOGIN_WITH_PASSWORD](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/auth/loginWithPassword", credentials)
        .then((data) => {
          if (data && data.result) {
            context.commit(SET_AUTH, data.result);
            resolve(data);
          } else {
            reject("服务器返回数据异常");
          }
        })
        .catch((error) => {
          if (error.response) {
            const { data, statusText } = error.response;
            const msg = data && data.msg ? data.msg : statusText;
            reject(msg);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [CHECK_SCAN_RESULT](context) {
    return new Promise((resolve, reject) => {
      ApiService.post("/auth/checkScanResult", { from: "portal" })
        .then((response) => {
          const { status, statusText, data } = response;
          if (status == 200) {
            if (data && data.result) {
              context.commit(SET_AUTH, data.result);
              resolve(data);
            } else {
              reject("服务器返回数据异常");
            }
            return;
          }
          if (status > 200 && status < 300) {
            reject(data);
          } else {
            const msg = data && data.msg ? data.msg : statusText;
            reject(msg);
          }
        })
        .catch((error) => {
          if (error.response) {
            const { statusText, data } = error.response;
            const msg = data && data.msg ? data.msg : statusText;
            reject(msg);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [LOGOUT](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("/auth/logout", data)
        .then((data) => {
          clearToken();
          context.commit(PURGE_AUTH);
          resolve(data);
        })
        .catch((error) => {
          context.commit(PURGE_AUTH);
          if (error.response) {
            const { data, statusText } = error.response;
            const msg = data && data.msg ? data.msg : statusText;
            reject(msg);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [GET_USER_INFO_API](context, data) {
    return new Promise(async (resolve, reject) => {
      try {
        const { result } = await getUserInfo();
        context.commit(SET_AUTH, result);
        resolve(result);
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        console.log(msg);
        reject("获取失败，请重试");
      }
    });
  },
  [VERIFY_AUTH](context) {
    const currentTime = new Date().format("yyyy-MM-dd hh:mm:ss");
    if (!state.currentUser || state.currentUser.login_expire_at < currentTime) {
      context.commit(PURGE_AUTH);
    }
  },
};

const mutations = {
  [SET_AUTH](state, user) {
    state.currentUser = user;
    setToken(user);
  },
  [PURGE_AUTH](state) {
    state.currentUser = null;
    clearToken();
  },
  // 注意: 在登出操作时手动提交此方法, 强制用户需短信登录, 但是在自动退出时不要调用
  [FORCE_LOGIN](state) {
    state.forceSmsLogin = true;
    window.localStorage.setItem("forceSmsLogin", JSON.stringify(true));
  },
  [SET_USER_INFO](state, data) {
    state.currentUser[data.key] = data.value;
    setToken(state.currentUser);
  },
};

const getters = {
  currentUser(state) {
    return state.currentUser;
  },
};

export default {
  namespace: false,
  state,
  getters,
  actions,
  mutations,
};
