/*
 * @Author: 大年
 * @Date: 2022-08-04 17:34:03
 * @Description: vant组件加载
 */
import {
  Swipe,
  SwipeItem,
  Field,
  Popup,
  Picker,
  Progress,
  PullRefresh,
  Button,
  Icon,
  Cell,
  Tabs,
  Tab,
  Tag,
  Dialog,
  List,
  ActionBar,
  ActionBarIcon,
  ActionBarButton,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Image,
  Divider,
  Uploader,
  ConfigProvider,
  IndexBar,
  IndexAnchor,
  Empty,
  Slider,
  Space,
  ShareSheet,
  Loading,
  NavBar,
  Switch,
} from "vant";
import { showImagePreview, showToast } from "vant";
import "vant/lib/index.css";
const loadVant = (app) => {
  app
    .use(Swipe)
    .use(SwipeItem)
    .use(Popup)
    .use(Picker)
    .use(Progress)
    .use(PullRefresh)
    .use(Field)
    .use(Button)
    .use(Icon)
    .use(Cell)
    .use(Tabs)
    .use(Tab)
    .use(Dialog)
    .use(List)
    .use(Loading)
    .use(Tag)
    .use(ActionBar)
    .use(ActionBarIcon)
    .use(ActionBarButton)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(Row)
    .use(Col)
    .use(Divider)
    .use(Uploader)
    .use(Slider)
    .use(Space)
    .use(ShareSheet)
    .use(IndexAnchor)
    .use(IndexBar)
    .use(ConfigProvider)
    .use(Empty)
    .use(NavBar)
    .use(Switch)
    .use(Image);
};

/**
 * @description: 图片查看
 * @param {*} param 字符串 || 数组
 * @return {*}
 */
const ImagePreviewFn = (param, startPosition = 0) => {
  if (typeof param === "string") {
    showImagePreview([param]);
    return;
  } else if (typeof param === "object") {
    showImagePreview(param, startPosition);
    return;
  }

  showToast("未知类型");
};

export { loadVant, ImagePreviewFn };
