import ApiService from "./api.service";

export function getAuthUrl(callback_url, state = "m") {
  const gateway = "https://open.weixin.qq.com/connect/oauth2/authorize";
  const redirect_uri = encodeURIComponent(callback_url);
  return `${gateway}?appid=${process.env.VUE_APP_MP_APP_ID}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
}

export function getOpenidAuthUrl(callback_url, state = "m") {
  const gateway = "https://open.weixin.qq.com/connect/oauth2/authorize";
  const redirect_uri = encodeURIComponent(callback_url);
  return `${gateway}?appid=${process.env.VUE_APP_MP_APP_ID}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=${state}#wechat_redirect`;
}

export function wechatAuth(data) {
  // return ApiService.post("/console/assets/update", data);
  return new Promise((resolve, reject) => {
    ApiService.post("/auth/wechatAuth", data)
      .then((data) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function signaturePack(url) {
  return new Promise((resolve, reject) => {
    ApiService.post("/auth/getWechatSignaturePack", { url })
      .then((data) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function prepareWechatRecharge(amount) {
  return new Promise((resolve, reject) => {
    ApiService.post("/pay/prepareRecharge", { amount })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function prepareIcbcRecharge(amount) {
  return new Promise((resolve, reject) => {
    ApiService.post("/pay/prepareIcbcRecharge", { amount })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getUserInfo(data) {
  return ApiService.post("/portal/user/getUserInfo", data);
}

export function wxpurchase(data) {
  return ApiService.post("/portal/plan/purchase", data);
}

export function bindWeixin(data) {
  return ApiService.post("/portal/user/bindWeixin", data);
}
