import ApiService from "./api.service";

//微信二维码登录
export function createUnionSessionWithQrScene(data) {
  return ApiService.post("/auth/createUnionSessionWithQrScene", data);
}

//检测是否扫码成功
export function checkScanResult(data) {
  return ApiService.post("/auth/checkScanResult", data);
}

//账号密码
export function login(data) {
  return ApiService.post("/auth/loginWithPassword", data);
}

//退出登录
export function logout(data) {
  return ApiService.post("/auth/logout", data);
}

//发送短信
export function smsSend(data) {
  return ApiService.post("/sms/send", data);
}

//用户注册
export function userRegister(data) {
  return ApiService.post("/common/userRegister", data);
}

//隐私
export function getPrivacy(data) {
  return ApiService.post("/common/getPrivacy", data);
}

//协议
export function getAgreement(data) {
  return ApiService.post("/common/getAgreement", data);
}

//合并
export function userMerge(data) {
  return ApiService.post("/portal/user/merge", data);
}
